@mixin submit-button(
  $bg-color: rgb(68, 29, 74),
  $border-color: rgb(68, 29, 74),
  $text-color: #f2e7e7,
  $hover-bg-color: #f2e7e7,
  $hover-text-color: rgb(55, 28, 60),
  $hover-border-color: #e4d1d1
) {
  background-color: $bg-color;
  border-color: $border-color;
  color: $text-color;
  font-size: 1.1rem;
  font-weight: 600;
  transition: background-color 0.5s, border-color 0.5s, color 0.5s;

  &:hover {
    background-color: $hover-bg-color;
    color: $hover-text-color;
    border-color: $hover-border-color;
    text-decoration: none;
  }
  &:focus {
    background-color: $hover-bg-color;
    color: $hover-text-color;
    border-color: $hover-border-color;
    text-decoration: none;
    outline: none;
    box-shadow: none;
  }
  &:focus-visible {
    outline: none;
    box-shadow: none;
  }
}

// Mixin for keyframe animation
@mixin keyframe-animation($name, $from-transform, $to-transform) {
  @-webkit-keyframes #{$name} {
    from {
      -webkit-transform: $from-transform;
    }
    to {
      -webkit-transform: $to-transform;
    }
  }
  @keyframes #{$name} {
    from {
      transform: $from-transform;
    }
    to {
      transform: $to-transform;
    }
  }
}

// Mixin for applying animation
@mixin apply-animation(
  $name,
  $duration: 1s,
  $iteration-count: 1,
  $fill-mode: forwards
) {
  -webkit-animation: #{$name} $duration $fill-mode;
  -webkit-animation-iteration-count: $iteration-count;
  -moz-animation: #{$name} $duration $fill-mode;
  -moz-animation-iteration-count: $iteration-count;
  -o-animation: #{$name} $duration $fill-mode;
  -o-animation-iteration-count: $iteration-count;
  -ms-animation: #{$name} $duration $fill-mode;
  -ms-animation-iteration-count: $iteration-count;
  animation: #{$name} $duration $fill-mode;
  animation-iteration-count: $iteration-count;
}

@include keyframe-animation('slideInTop', translateY(-100%), translateY(0));
@include keyframe-animation('slideInBottom', translateY(100%), translateY(0));
@include keyframe-animation('slideInLeft', translateX(-100%), translateX(0));
@include keyframe-animation('slideInRight', translateX(100%), translateX(0));
