@import '../../styles/_mixins.scss';

#pricing-list {
  .card-header {
    background-color: #f2e7e7;
    border-color: #f2e7e7;
    color: #0d0d0d;

    h4 {
      font-size: 1.2rem;
      color: #441d4a;
    }
  }

  .card-body {
    h4.card-title {
      color: rgb(212, 0, 94);
    }
    .row {
      margin-left: -2px;
    }
  }

  .card-footer {
    a,
    a:visited {
      text-decoration: none;
    }

    button[type='submit'] {
      @include submit-button;
    }
  }
}
