$font-stack: Inter, sans-serif;
$primary-color: #441d4a;
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
);

html {
  font-size: 14px;
}

body {
  font: $font-stack;
  color: $primary-color;
}

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.container {
  max-width: map_get($container-max-widths, lg);
}

.coming-soon {
  letter-spacing: -0.02em;
  color: rgb(210, 0, 95);
}

/*
.nexa-font {
  font-family: 'NexaFont', sans-serif;
}
@font-face {
  font-family: 'NexaFont';
  src: url('https://www.barbcut.com/fonts/Nexa.otf') format('opentype');
}
*/
