@import '../../styles/_mixins.scss';
$max-width: 430px;
$form-max-width: 400px;
$form-input-bg-color: #fdfbfb;

#contactus-container {
  max-width: $max-width;
  h4 {
    color: rgb(210, 0, 95);
  }
  form {
    max-width: $form-max-width;
    input[type='text'],
    input[type='email'] {
      background-color: $form-input-bg-color;
      &[disabled] {
        color: gray;
        background-color: #f3f3f3;
        //cursor: not-allowed;
      }
      &::placeholder {
        color: #6c757d;
        opacity: 0.6;
      }
    }
    #phone {
      display: none;
      border: none;
      background-color: #ffffff;
      color: #ffffff;
    }
    button[type='submit'] {
      @include submit-button;
    }
  }

  label {
    font-weight: 500;
    font-size: 0.9rem;
    margin-left: 3px;
    margin-bottom: 2px;
  }
}
