$header-background-color: #fdfbfb;

$header-link-color: rgba(0, 0, 0, 0.7);
$header-link-hover-color: rgba(0, 0, 0);
$header-link-active-color: #000000;
$header-link-active-hover-color: #000000;

#bg-header {
  .nav-item {
    .nav-link {
      font-weight: 400;
      font-size: 1.1rem;
      color: $header-link-color;
      &:hover {
        color: $header-link-hover-color;
        text-shadow: 1px 1px 20px #f9d0d0;
      }
    }
  }
  .nav-item.active {
    .nav-link {
      color: $header-link-active-color;
      text-shadow: 1px 1px 5px #f9d0d0;
      &:hover {
        color: $header-link-active-hover-color;
      }
    }
  }
  background-color: $header-background-color;
}
