@import '../../styles/_mixins.scss';

#home-container {
  .headline {
    letter-spacing: -0.02em;
    margin-bottom: 0.5em;
    font-size: 1.8em;
    line-height: 1em;
    font-family: Inter, sans-serif;
    font-weight: 600;

    .start {
      color: rgb(4, 56, 125);
    }
    .end {
      letter-spacing: -0.02em;
      color: rgb(210, 0, 95);
    }
  }
  h4.feature-title {
    color: rgb(212, 0, 94);
  }
  .staff-image {
    box-shadow: 0 0.5rem 1rem rgba(195, 176, 149, 0.8);
  }
  // apply-animation($name, $duration: 1s, $iteration-count: 1, $fill-mode: forwards)
  .slide-in-heading {
    @include apply-animation('slideInLeft', 1s);
  }
  .slide-in-customer {
    @include apply-animation('slideInRight', 1s);
  }
  .slide-in-staff {
    @include apply-animation('slideInRight', 1.4s);
  }
  .slide-in-owner {
    @include apply-animation('slideInRight', 1.8s);
  }
}
